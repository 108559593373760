<template>
  <div class="foot">
    <div class="foot_link">
      <div class="foot_right">
        <img src="../../assets/logo-footer.png"/>
        <div class="phone">联系电话：400-780-0159(服务热线)</div>
        <div class="email">邮箱账号：tianhua@skygovern.com</div>
        <div class="company">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 址：金华市金东区多湖街道光南路广润翰城北门4幢120室</div>
      </div>
      <div class="foot_left">
        <!-- <div class="left_box">友情链接</div> -->
        <div class="left_box">
          <div class="title">新闻中心</div>
          <div class="sub_title" @click="goNews(0)">公司新闻</div>
          <div class="sub_title" @click="goNews(1)">行业动态</div>
          <div class="sub_title" @click="goNews(2)">行业政策</div>
        </div>
        <div class="left_box">
          <div class="title">关于我们</div>
          <div class="sub_title" @click="goAbout(0)">关于天华</div>
          <div class="sub_title" @click="goAbout(1)">企业文化</div>
          <div class="sub_title" @click="goAbout(3)">加入我们</div>
        </div>
      </div>
    </div>
    <div class="ICP">
      <div>Copyright © 2007-2010 All Rights Reserved 天华科技版权所有</div>
      <img src="../../assets/homepage/insignia.png"/>
      <div>
        <span
        ><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070302100119"
            target="_blank"
        >浙公网安备 33070302100119号</a
        ></span
        >
        <span
        ><a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
        >浙ICP备10213264号-1</a
        >
        </span>
        <span
        ><a href="https://www.miit.gov.cn" target="_blank">
            中华人民共和国工业和信息化部</a
        ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot-bar",
  components: {},
  data() {
    return {};
  },

  mounted() {
  },
  methods: {
    goNews(category) {
      this.$router.push({
        name: "news",
        query: {
          categoryId: category,
        },
      });
    },
    goAbout(category) {
      this.$router.push({
        name: "aboutUs",
        query: {
          activeTab: category,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.foot {
  //margin-top: 20px;
  position: relative;

  .foot_link {
    width: 100%;
    height: 300px;
    background-image: url("https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/foot.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .foot_right {
      position: absolute;
      left: 360px;
      top: 70px;
      text-align: left;
      color: rgba(187, 187, 187, 1);
      font-size: 18px;
      font-weight: 500;

      img {
        width: 167px;
        height: 55px;
        margin-bottom: 6px;
        // margin-bottom: 20px;
      }
      div{
        line-height: 21px;
        margin-bottom: 10px;
      }

    }

    .foot_left {
      position: absolute;
      right: 360px;
      top: 70px;
      text-align: left;
      color: rgba(187, 187, 187, 1);
      font-size: 18px;
      display: flex;
      flex-direction: row;
      font-weight: bold;

      .left_box {
        margin-left: 80px;
        font-size: 18px;
        font-weight: 800;
        color: #ffffff;
        line-height: 21px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          line-height: 21px;
          margin-bottom: 8px;
        }

        .sub_title {
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          color: #bbbbbb;
          line-height: 16px;
        }
      }
    }
  }

  .ICP {
    background-color: rgba(3, 5, 7, 1);
    width: 100%;
    height: 60px;
    font-size: 14px;
    color: rgba(187, 187, 187, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: -1px;

    img {
      width: 20px;
      height: 20px;
      margin: 0px 20px;
    }

    span {
      margin-right: 20px;
    }

    a {
      font-size: 14px;
      color: rgba(187, 187, 187, 1);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
