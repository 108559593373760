<!--
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 案例：导航菜单
 * @FilePath: \tp-vue2\src\page\index\nav-bar.vue
-->

<template>
  <div :class="isScroll ? 'roll_body' : 'body'">
    <div class="w1320 flex-row flex-between flex-align box">
      <img :src="logoUrl"/>
      <div class="flex-row flex-align custom-nav-bar-normal h100">
        <div
            :class="
            current != index
              ? 'div-transition h100 flex-column flex-center'
              : 'div-transition h100 flex-column flex-center bg-blue'
          "
            v-for="(item, index) in navBars"
            :key="index"
            @click="handleNavBar(item, '')"
        >
          <span
              :class="
              current == index
                ? 'item-label item-list-normal'
                : 'item-label right-line'
            "
          >{{ item.label }}</span
          >
        </div>
      </div>
      <!-- <div
        :class="
          current != 0 || isScroll
            ? 'flex-row flex-align custom-nav-bar-normal h100'
            : 'flex-row flex-align custom-nav-bar h100'
        "
      >
        <div
          class="div-transition h100 flex-column flex-center"
          v-for="(item, index) in navBars"
          :key="index"
          @click="handleNavBar(item, '')"
        >
          <span
            :class="
              current == index
                ? current != 0 || isScroll
                  ? 'item-list-normal'
                  : 'item-list'
                : ''
            "
            >{{ item.label }}</span
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/eventBus/index.js";

export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      isMenuShow: false,
      current: 0,
      navBars: [
        {
          label: "企业首页",
          value: "/",
          index: 0,
        },

        {
          label: "产品案例",
          value: "/product",
          index: 1,
        },
        // {
        //   label: "案例展示",
        //   value: "/casus",
        //   index: 3,
        // },
        {
          label: "新闻中心",
          value: "/news",
          index: 2,
        },
        {
          label: "关于我们",
          value: "/about/aboutus",
          index: 3,
        },
        // {
        //   label: "联系我们",
        //   value: "/contact",
        //   index: 5,
        // },
        /*{
          label: '登陆页面',
          value: '/login'
        },*/
      ],
      currentAddress: "",
      logoUrl: require("@/assets/logo-nav.png"),
      childList: [
        // {
        //   label: "产品详情",
        //   value: "/product/detail",
        //   index: 2,
        // },
        {
          label: "案例详情",
          value: "/casus/detail",
          index: 1,
        },
        {
          label: "案例列表",
          value: "/casus",
          index: 1,
        },
      ],
      scroll: "",
      isScroll: false,
    };
  },
  watch: {
    $route: "newAddress",
  },
  created() {
    this.newAddress();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * @description: 路由跳转
     * @param {*} path 路径
     * @return {*}
     */
    handleNavBar(item) {
      this.current = item.index;
      this.$router.push({path: item.value});
      // 下面的if是为了切换tab时重置产品案例页面
      if (item.value === '/product') {
        this.$store.commit('user/activeState', null)
      }
    },
    handleNavBar1(item, item1) {
      this.current = item.index;
      this.$router.push({path: item1.value});
    },

    handleLogout: function () {
      this.$store.dispatch("logout").then(() => {
        // 在这里处理登出成功后的逻辑
        console.log("登出成功");
      });
    },
    newAddress() {
      let path = this.$route.path;
      //是不是子页面
      let isChild = true;
      this.navBars.forEach((res) => {
        if (path == res.value) {
          this.current = res.index;
          isChild = false;
        }
      });
      if (isChild) {
        this.childList.forEach((res) => {
          if (path.includes(res.value)) {
            this.current = res.index;
          }
        });
      }
    },
    theCurrent() {
      let path = this.$route.path;
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
      //导航栏高度70px 超过70显示白底
      if (this.scroll > 70) {
        this.isScroll = true;
        this.logoUrl = require("@/assets/logo-nav.png");
      } else {
        this.isScroll = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  // background-color: #0d1c2e;
  background: rgba(255, 255, 255, 0.9);
}

.roll_body {
  background-color: white;
}

.box {
  height: 70px;
  width: 1440px;
  margin: 0 auto;

  img {
    width: 213px;
    height: 70px;
  }
}

.nav-main {
  font-size: 24px;
  background-color: #0d1c2e;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.nav-nums {
  width: 130px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.item-list {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: bold;
}

.item-list-normal {
  color: #ffffff !important;
  font-weight: bold;
  border-right: none;
}

.nav-nums li {
  display: none;
  list-style: none;
  height: 40px;
  line-height: 40px;
}

.nav-nums:hover li {
  display: block;
}

.custom-nav-bar > div {
  padding: 0 33px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  position: relative;
}

.custom-nav-bar > div:last-child {
  // margin-right: 0;
}

.custom-nav-bar > div:hover {
  color: rgba(255, 255, 255, 1);
}

.custom-nav-bar-normal > div {
  // padding: 0 33px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  position: relative;
}

.custom-nav-bar-normal > div:last-child {
  .item-label {
    border-right: none;
  }
}

.custom-nav-bar-normal > div:hover {
  color: rgba(0, 106, 255, 1);
}

// .custom-nav-bar > div:nth-of-type(3):hover {
//   .navbar-list {
//     display: flex;
//   }
// }

.navbar-list {
  width: 232px;
  background: #fff;
  border-radius: 6px;
  //margin-top: 300px;
  position: absolute;
  top: 130px;
  left: 50%;
  //transform: translateX(-50%);
  margin-left: -116px;
  z-index: 20;
  display: none;
}

.item-label {
  height: 36px;

  line-height: 36px;
  padding: 0 25px;
}

.right-line {
  border-right: 2px solid #d9d9d9;
}

.bg-blue {
  background-color: #217eff;
}

// .item-label:last-child {
//   border-right: none;
// }

// .navbar-list > span {
//   padding: 16px 0;
//   color: #333333;
//   border-bottom: 1px solid #e5e5e5;
//   width: 100%;
// }

// .navbar-list > span:hover {
//   color: rgba(255, 255, 255, 1);
// }

// .navbar-list > span:last-child {
//   border-bottom: none;
// }

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
